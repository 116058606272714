import { OnlinePrediction } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Switch, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changeUserStatus, fetchUsers } from "../actions/users";


const rolesOptions = [
    {
        id:4,
        name:'Todos',
        filter:''
    },
    {
        id:1,
        code:'admin',
        name:'Administrator ROLE',
        filter:'role_id=1'
    },
    {
        id:2,
        code:'physician',
        name:'Physician ROLE',
        filter:'role_id=2'

    },
    {
        id:3,
        code:'patient',
        name:'Patient ROLE',
        filter:'role_id=3'
    }
];

const enabledOptions = [
    {
        id:3,
        name:'Todos',
        filter:''
    },
    {
        id:1,
        name:'Solo activos',
        filter:'enabled=true'
    },
    {
        id:2,
        name:'Solo inactivos',
        filter:'enabled=false'
    }
]

const registryOptions = [
    {
        id:3,
        name:'Todos',
        filter:''
    },
    {
        id:1,
        name:'Solo registrados',
        filter:'email_verified_at=true'
    },
    {
        id:2,
        name:'Solo No registrados',
        filter:'email_verified_at=false'
    }
];

const queryParams = (filter) => {

    let params = [];

    params.push(enabledOptions.filter(option => option.id === filter.enabled_id)[0].filter);
    params.push(registryOptions.filter(option => option.id === filter.registry_id)[0].filter);
    params.push(rolesOptions.filter(option => option.id === filter.role_id)[0].filter);

    return params.filter(word=>word!='');
}

const UserFilter = ({disabled,initFilter, onChange}) => {

    const [filter,setFilter] = useState(initFilter);
    const handleChange = (event) => {
        setFilter({...filter, [event.target.name]:  event.target.value});
        onChange(queryParams({...filter, [event.target.name]:  event.target.value}));
    }

    return <Grid container alignItems={"center"} justifyContent={"end"} spacing={1}>
            <Grid item xs={1}>
                <Typography variant="h6">Filtros</Typography>
            </Grid>
            <Grid item xs={2}> 
                <TextField id="registry_id" size="small" name="registry_id" label="Registro" fullWidth select value={filter.registry_id || ''} onChange={handleChange} disabled={disabled}>
                    {
                        registryOptions.map(registry => (
                            <MenuItem key={registry.id} value={registry.id}>
                                {registry.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Grid>
            <Grid item xs={2}> 
                <TextField id="enabled_id" size="small" name="enabled_id" label="Estado" fullWidth select value={filter.enabled_id || ''} onChange={handleChange} disabled={disabled}>
                    {
                        enabledOptions.map(enabled => (
                            <MenuItem key={enabled.id} value={enabled.id}>
                                {enabled.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Grid>

            <Grid item xs={2}> 
                <TextField id="role_id" size="small" name="role_id" label="Rol" fullWidth select value={filter.role_id || ''} onChange={handleChange} disabled={disabled}>
                    {
                        rolesOptions.map(role => (
                            <MenuItem key={role.id} value={role.id}>
                                {role.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Grid>
    </Grid>}

export default UserFilter;
