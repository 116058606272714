import { createSelector } from 'reselect'

export const selectFullUsers = (state) => {
  return state.users;
}

export const selectUsers = (state) => {
  return state.users.data;
}

export const selectUsersLoading = (state) => {
  return state.users.loading;
}

export const filterUsers = (filter) => {

        return createSelector(
            selectUsers,
            users => filter === "" ? users : users.filter(user => user.email?.toLowerCase().includes(filter.toLowerCase()) || user.name?.toLowerCase().includes(filter.toLowerCase()) || user.whatsapp?.toLowerCase().includes(filter.toLowerCase())))
}