import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { clearUsers, fetchUser, fetchUsers, setUser } from '../actions/users';
import { filterUsers, selectUsersLoading } from '../selectors/users';

import {IconButton, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {  Visibility } from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import UserDialog from './UserDialog';
import UserSwitch from '../components/UserSwitch';
import UserFilter from '../components/UserFilter';
import UserStatus from '../components/UserStatus';


const UserAdmin = () => {

    const dispatch = useDispatch();
    const [filters,setFilters] = useState([]);
    const [userFilter, setUserFilter] = useState('');
    const [userDialog, openUserDialog] = useState(false);

    useEffect(() => {
        dispatch(fetchUsers());
        return () => {
            dispatch(clearUsers);
        }
    }, []);


    const userLoading = useSelector(selectUsersLoading);
    const users =  useSelector(filterUsers(userFilter));
    
    const userType = (role) => {
        switch (role.code) {
            case 'physician': return <MedicalServicesIcon/>;
            case 'patient': return <AccountCircleIcon/>;
            case 'admin': return <AdminPanelSettingsIcon/>;
            default: return null;
          }     
    }

    const handleFilter = (filters) => {

        setFilters(filters);
        dispatch(fetchUsers(filters));
    }
    const handleViewUser = (user) => {
        openUserDialog(user);
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 250,
            sortable: false,
            hide: true,
            type: 'number'
        },
        {
            field: 'email',
            headerName: 'Email',
            sortable: true,
            flex: 1,
            type: 'email',
            minWidth: 85
            
        },
        {
            field: 'whatsapp',
            headerName: 'WhatsApp',
            sortable: true,
            flex: 1,
            type: 'string',
            minWidth: 85
            
        },
        {
            field: 'fullName',
            headerName: 'Nombre completo',
            sortable: true,
            flex: 1,
            type:'string',
            minWidth: 85,
            valueGetter: (params) => `${params.row.name}`
        },
        {
            field: 'role',
            headerName: 'Tipo',
            sortable: false,
            flex: 1,
            type: 'string',
            renderCell: (params) => userType(params.row.role)
        },
        {
            field: 'enabled',
            headerName: 'Estado',
            sortable: false,
            flex: 0.5,
            minWidth: 110,
            renderCell: (params) => <UserStatus {...params.row}/>
        },
        {
            field: 'view',
            headerName: 'Ver',
            minWidth: 110,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => <IconButton onClick={()=>handleViewUser(params.row)}><Visibility/></IconButton>
        }
    ];

    const data = {
        rows: users,
        columns: columns
    }


    return (
        <Fragment>

        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Administracion de usuarios</Box></Typography>
            </div>

            
            <div style={{  marginLeft:'3em', marginRight:'3em', marginTop:'1em',marginBottom:'1em'}}>
                <TextField
                    placeholder="Buscar usuario"
                    size="small"
                    fullWidth
                    onChange={(event) => setUserFilter(event.target.value)}/>
            </div>

            <div style={{  marginLeft:'3em', marginRight:'3em', marginTop:'1em',marginBottom:'1em'}}>
                <UserFilter 
                    disabled={userLoading}
                    initFilter={{enabled_id:3, registry_id:3, role_id:4}} 
                    onChange={(filters)=>handleFilter(filters)} />
            </div>

            <div style={{ height: '1em' }}>
                {   
                    userLoading &&
                    <LinearProgress color="primary" />
                }
            </div>
            
            <div style={{ flexGrow: '1', overflowY: 'auto', marginLeft:'3em',marginRight:'3em',marginBottom:'3em'}}>
                <DataGridPro
                    {...data}
                    headerHeight={60}
                    rowHeight={40}
                    disableMultipleSelection
                    disableColumnMenu
                    disableColumnSelector
                    disableMultipleColumnsSorting
                    hideFooter
                />
            </div>



        </div >

                {
                    userDialog && <UserDialog open={userDialog} close={()=>openUserDialog(false)} id={userDialog.id}/>
                }

        </Fragment>
    );
};

export default UserAdmin;