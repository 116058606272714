import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Test from './Test';

const Study = ({ study }) => {

    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div id={`study_${study.id}`}
            style={{ display: 'flex', flexDirection: 'column', borderColor: '#f4f4f4', borderStyle: 'solid', borderWidth: '0.5', borderRadius: '5', marginLeft: matchMD ? '1em' : '3em', marginBottom: '0.5em' }}>
            <div style={{ padding: '1em' }}>
                <Typography variant="h5"><Box fontWeight={600}>{study?.study_type_name}</Box></Typography>
                <Typography variant="h5">{study?.method}</Typography>
            </div>
            {
                study?.tests?.map(test => <Test key={test.id} test={test} />)
            }
        </div>
    );
};

export default Study;